<template>
    <div class="wrapper-listado noticias">

        <div class="header-grid row-between">

            <h2>Entrevistas</h2>

            <div class="buscador row-end">

                <input type="text" name="carrera" placeholder="Buscar" autocomplete="nope" v-model="search" @keyup.enter="setData">

                <button @click="setData" class="boton-azul"><i class="fa fa-search"></i></button>

            </div>

        </div>


        <div class="contenedor-listados">
            <section class="listado-grid">

                <template v-if="data.length != 0">

                    <article data-carrera="Biotecnología" :key="key" v-for="(el,key) in data" :style="'background:#444 url('+el.header_img.url+') center/cover no-repeat;'" class="small">
                        <router-link :to="'/es/comunicacion/entrevistas/'+el.slug" class="enlace-cover"></router-link>
                        <div class="contenedor-texto">
                            <h2>{{el.title}}</h2>
                            <h3 class="carrera" v-if="!!el.study">{{el.study.name}} {{el.promotion}}</h3>
                            <h3 class="carrera" v-if="el.promo_elu_date">Promoción ELU {{el.promo_elu_date}}</h3>
                            <h4 class="titular">{{el.header}}</h4>
                        </div>
                    </article>

                </template>

            </section>
        </div>


        <nav class="paginador" v-if="pages > 1">
            <a v-if="page > 1" @click="prev" class="prev" data="prev"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
            <template v-for="(n,key) in pages">
                <a @click="setPage(n)" data="1" :class="(n == page) ? 'activo' : ''" :key="key" v-if="n == (page-1) || n == (page+1) || page == n">{{n}}</a>
            </template>
            <a v-if="page < pages" @click="next" class="next" data="next"><i class="fa fa-caret-down" aria-hidden="true"></i></a>
        </nav>


    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'noticias',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            perpage: 5,
            page: 1,
            total: 0,
            search: ''
        }),
        methods: {
            ...mapActions(['requestEntrevistas']),
            setData() {

                let data = {
                    length: this.perpage,
                    page: this.page,
                    filters: []
                };

                if (this.search) {

                    let searchIn = ['title', 'promotion', 'study.name'];

                    searchIn.forEach(id => {

                        let el = {
                            filterPack: true,
                            filters: [
                                {
                                    "name": id,
                                    "search": this.search,
                                    "textFilter": 1
                                }
                            ]
                        }

                        data.filters.push(el);

                    });

                }

                this.requestEntrevistas(data).then(r => {
                    this.data = r.data;
                    this.total = r.recordsFiltered;
                });
            },
            next() {
                if (this.page < this.pages) {
                    this.page++;
                    this.setData();
                }
            },
            prev() {
                if (this.page > 1) {
                    this.page--;
                    this.setData();
                }
            },
            setPage(n) {
                this.page = n;
                this.setData();
            }
        },
        computed: {
            pages() {
                return Math.ceil(this.total / this.perpage);
            }
        }
    }

</script>


<style scoped lang="scss">
    .listado-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px;
        grid-template-areas:
            "first first . ."
            "first first . .";
    }


    @media screen and (max-width:1200px) {
        .listado-grid {
            min-height: 615px;
        }
    }

    @media screen and (min-width:1023px) {
        .listado-grid .small {
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (max-width:1023px) {
        .listado-grid {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 0;
            gap: 0;
        }

    }

    .listado-grid .small:first-child {
        grid-area: first;
    }
</style>